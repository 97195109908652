import React from "react";
import pptxgen from "pptxgenjs";
import { Box, Button } from "grommet";
import reactImageSize from "react-image-size";

export default class PPTXExporter extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  getMediaUrl = (extendedEntities) => {
    if (
      extendedEntities &&
      extendedEntities.media &&
      extendedEntities.media.length > 0
    ) {
      const imageUrl = extendedEntities.media[0].media_url_https;
      return imageUrl;
    }

    return null;
  };

  export = async () => {
    // 1. Create a new Presentation
    const pres = new pptxgen();

    let { items } = this.props;

    items = Object.values(items).filter((item) => item.include);

    for (const item of items) {
      const slide = pres.addSlide();

      slide.addText(item.text, {
        x: "5%",
        y: "5%",
        w: "90%",
        h: "40%",
        valign: "top",
        align: "center",
        shrinkText: true
      });

      const mediaUrl = this.getMediaUrl(item.extended_entities);
      if (mediaUrl) {
        const { width, height } = await reactImageSize(mediaUrl);
        const aspectRatio = height > 0 ? width / height : 1;
        const widthPercent =
          Math.round((aspectRatio >= 1 ? 100 : 100 * aspectRatio) * 100) / 100;
        const heightPercent =
          Math.round((aspectRatio <= 1 ? 100 : 100 / aspectRatio) * 100) / 100;

        const imageBoxWidth = 5;
        const imageBoxHeight = 2.5;

        const imageWidth = widthPercent/100 * imageBoxWidth / 2;
        const imageHeight = heightPercent/100 * imageBoxHeight;

        slide.addImage({
          x: "25%",
          y: "50%",
          path: mediaUrl,
          w: imageWidth,
          h: imageHeight,
          sizing: {
            type: "contain",
            w: imageBoxWidth,
            h: imageBoxHeight,
          },
        });
      }
    }

    // 4. Save the Presentation
    pres.writeFile();
  };

  render() {
    return (
      <Box size="small">
        <Button
          primary
          color="neutral-2"
          label="MAKE POWERPOINT"
          border={{ radius: "0" }}
          onClick={() => this.export()}
        />
      </Box>
    );
  }
}
