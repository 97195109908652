import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

import PPTXExporter from "./PPTXExporter";
import config from "../config";
import { CheckBox, Box, TextInput, Button, Text, Heading } from "grommet";
import Tweet from "./Tweet";

class TweetList extends React.Component {
  reconnectAttempts = 0;

  maxReconnectAttempts = 10;

  listSize = 25;

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      retrieveConversation: true,
      filterToAuthor: true,
      includeAttributionSlide: true,
    };

    this.accessToken = props.accessToken;
    this.accessTokenSecret = props.accessTokenSecret;
    this.screenName = props.screenName;
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    const { tweetId } = this.state;
    this.retrieveConversation(tweetId);
  };

  // Put in api service
  retrieveConversation(tweetId) {
    if (!tweetId || tweetId.length === 0) {
      console.log(`not retrieving empty search: ${tweetId}`);
      return;
    }

    axios
      .get(
        `${config.BACKEND}/tweet?q=${tweetId}&screenName=${this.screenName}&accessToken=${this.accessToken}`
      )
      .then((response) => {
        if (response.data) {
          let originalItem = response.data[0];

          if (originalItem) {
            originalItem.text = originalItem.full_text;
            originalItem.include = true;

            if (originalItem.user) {
              originalItem.user.username = originalItem.user.screen_name;
              this.updateConversationItems(
                tweetId,
                originalItem.user.id_str,
                originalItem
              );
            }
          }
          this.setState({ originalItem });
        }
      });
  }

  updateConversationItems = (tweetId, authorId, tweet) => {
    const { filterToAuthor } = this.state;

    const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const olderThanSevenDays = new Date(tweet.created_at) <= sevenDaysAgo;

    if (olderThanSevenDays) {
      // Error
    } else {
      this.fetchConversation(tweetId, filterToAuthor, authorId);
    }
  };

  fetchConversation = (tweetId, filterToAuthor, authorId) => {
    let url = `${config.BACKEND}`;
    url += `/conversation?q=${tweetId}&screenName=${this.screenName}&accessToken=${this.accessToken}`;

    if (filterToAuthor) {
      url += `&authorId=${authorId}`;
    }

    axios.get(url).then((response) => {
      if (response.data && response.data.data && response.data.includes) {
        let users = {};

        if (response.data.includes.users) {
          response.data.includes.users.map((user) => (users[user.id] = user));
        }

        let items = response.data.data;
        let includes = response.data.includes;

        Promise.all(
          (items = items.map((item) => {
            item.user = item.author_id in users ? users[item.author_id] : {};
            item.include = true;

            if (
              includes &&
              includes.media &&
              item.attachments &&
              item.attachments.media_keys &&
              item.attachments.media_keys.length > 0
            ) {
              let mediaKey = item.attachments.media_keys[0];
              includes.media.forEach((mediaItem) => {
                if(mediaItem.media_key === mediaKey){
                  let url = null;
                  if(mediaItem.url){
                    url = mediaItem.url;
                  } else if(mediaItem.preview_image_url){
                    url = mediaItem.preview_image_url
                  }
                  item.extended_entities = {
                    media: [
                      {
                        media_url_https: url,
                        ...mediaItem
                      }
                    ]
                  }
                }
              });
            }

            return item;
          }))
        ).then((items) => {
          this.setState({ items });
        });
      } else if (response.data && response.data.status === 401) {
        this.setState({ authFailed: true });
      }
    });
  };

  getItems = () => {
    const { originalItem, items } = this.state;
    let result = [];

    if (items) {
      items.forEach((item) => result.push(item));
    }
    if (originalItem && originalItem.user) {
      result.unshift(originalItem);
    }

    result.sort((a, b) => {
      const t1 = new Date(a.created_at);
      const t2 = new Date(b.created_at);
      if (t1 > t2) {
        return 1;
      } else if (t1 < t2) {
        return -1;
      } else {
        return 0;
      }
    });

    result = result.map((item) => {
      if (!item.text) {
        item.text = "";
      }
      item.text = item.text.replace(/https:\/\/t.co\/[a-zA-Z0-9]*/, "");
      item.text = item.text.replace(/&amp;/, "&");
      item.text = item.text.replace(/&nbsp;/, " ");
      return item;
    });

    return result;
  };

  getSearchFontSize = (value) => {
    let fontSize = 20;

    if (value) {
      if (value.length) {
        if (value.length >= 12) {
          fontSize -= value.length * 0.4;
        }
      }
    }

    return fontSize;
  };

  setTweetValue = (value) => {
    const url = value;
    let tokens = url ? url.split("/") : [];
    const tweetIdIncQuery = tokens.length > 0 ? tokens[tokens.length - 1] : "";
    tokens = tweetIdIncQuery ? tweetIdIncQuery.split("?") : [];
    const tweetId = tokens.length > 0 ? tokens[0] : "";
    this.setState({ tweetId: tweetId });

    if(tweetId === '' || tweetId === null){
      this.setState({originalItem: null, items: null})
    }
  };

  setIncludeAttributionSlide = (value) => {
    this.setState({ includeAttributionSlide: value });
  };

  setFilterToAuthor = (value) => {
    this.setState({ filterToAuthor: value }, this.handleSearch());
  };

  setItemIncludeState = (id, include) => {
    let { originalItem, items } = this.state;

    if (originalItem && originalItem.id === id) {
      originalItem.include = include;
      this.setState({ originalItem });
    }
    if (items) {
      items.forEach((item) => {
        if (item.id === id) {
          item.include = include;
        }
      });
      this.setState({ items });
    }
  };

  render() {
    const { includeAttributionSlide, authFailed, filterToAuthor } = this.state;

    const items = this.getItems();

    let displayItems = [];
    if (items) {
      items.forEach((item) => displayItems.push(item));
    }

    let itemCount = 0;
    const itemsCards = displayItems.map((x) => {
      itemCount++;
      return (
        <Tweet
          key={x.id}
          data={x}
          number={itemCount}
          total={displayItems.length}
          onInclude={(include) => this.setItemIncludeState(x.id, include)}
        />
      );
    });

    if (authFailed) {
      return <Redirect to="/" />;
    }

    return (
      <Box>
        <Box>
          <Box
            margin={{ vertical: "medium" }}
            direction="row-responsive"
            gap="xsmall"
          >
            <TextInput
              placeholder="Enter the URL of the tweet"
              value={this.state.tweetValue}
              onChange={(event) => this.setTweetValue(event.target.value)}
              onKeyPress={(event) => this.handleKeyPress(event)}
            />
            <Button
              primary
              label="RETRIEVE"
              border={{ radius: "0" }}
              onClick={this.handleSearch}
              onKeyPress={(event) => this.handleKeyPress(event)}
            />
          </Box>
          <Box
            gap="small"
            pad={{ bottom: "medium" }}
            border={{
              side: "bottom",
            }}
          >
            <CheckBox
              checked={filterToAuthor}
              label={
                <Text color="dark-1" size="small">
                  Include only tweets by original author
                </Text>
              }
              onChange={(event) => this.setFilterToAuthor(event.target.checked)}
            />
            {/* <CheckBox
              checked={includeAttributionSlide}
              label={
                <Text color="dark-1" size="small">
                  Include attribution slide
                </Text>
              }
              onChange={(event) =>
                this.setIncludeAttributionSlide(event.target.checked)
              }
            /> */}
          </Box>
        </Box>
        <Heading level="2" pad="small" color="dark-1">
          Tweets
        </Heading>
        <Box justify="center">
          {displayItems.length > 0 ? (
            <Box justify="center">
              <Box
                gap="small"
                pad="small"
                justify="center"
                border={{
                  side: "bottom",
                }}
              >
                <Text>Found {displayItems.length} tweets matching search.</Text>
                <Text>Select tweets below to include in powerpoint.</Text>
                <Box align="center" gap="small">
                  <PPTXExporter items={items} />
                </Box>
              </Box>
              <Box gap="small">{itemsCards}</Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
}
export default TweetList;
