import React from "react";
import { Image, Box, CheckBox, Text } from "grommet";
import styles from "../styles/StreamPage.module.css";

class TweetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTweetUrl = (item) => {
    let tweetUrl = `https://twitter.com/${item.user.screen_name}`;

    if (
      item &&
      item.entities &&
      item.entities.urls &&
      item.entities.urls.length > 0 &&
      item.entities.urls[0].expanded_url
    ) {
      tweetUrl = item.entities.urls[0].expanded_url;
    }

    return tweetUrl;
  };

  getImage = (extendedEntities) => {
    if (
      extendedEntities &&
      extendedEntities.media &&
      extendedEntities.media.length > 0
    ) {
      const imageUrl = extendedEntities.media[0].media_url_https;
      return <img alt="content" src={imageUrl} />;
    }

    return null;
  };

  setIncluded = (included) => {
    this.props.onInclude(included);
  };

  render() {
    const { data } = this.props;

    return data ? (
      <Box id="list-item" key={data.id}>
        <Box
          className={styles.card}
          pad="small"
          margin="small"
          border={{ size: "small" }}
        >
          <Box gap="small">
            <Box direction="row" gap="medium">
              <Box height="xxsmall" width="xxsmall">
                <a href={data.user.profile_image_url}>
                  <Image src={data.user.profile_image_url} />
                </a>
              </Box>
              <Box>
                <Box direction="row">
                  <Text>{data.user.name}&nbsp;•&nbsp;</Text>
                  <Text>@{data.user.username}&nbsp;•&nbsp;</Text>
                  <Text>
                    {new Date(data.created_at).toLocaleDateString() +
                      " " +
                      new Date(data.created_at).toLocaleTimeString()}
                  </Text>
                </Box>
              </Box>
              <Box flex="grow" direction="column" align="end" size="small">
                <CheckBox
                  checked={data.include}
                  onChange={(event) => this.setIncluded(event.target.checked)}
                  toggle
                  height="xsmall"
                />
                <Text color="dark-1" size="small">
                  INCLUDE
                </Text>
              </Box>
            </Box>
            <Box>
              <Text>{data.text}</Text>
            </Box>
          </Box>
          <br/>
          <Box pad="small">
            {data.extended_entities
              ? this.getImage(data.extended_entities)
              : null}
          </Box>
        </Box>
        {/* </a> */}
      </Box>
    ) : null;
  }
}

export default TweetComponent;
