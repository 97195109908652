import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import ConverterPage from "./pages/ConverterPage";
import LoginPage from "./pages/LoginPage";
import "./App.css";
import { Grommet, Header, Anchor, Box, Image, Text, Heading } from "grommet";
import theme from "./styles/GrommetTheme";
import logo from "./assets/images/logo-header.png";

library.add(fab);

export const App = () => (
  <Grommet theme={theme}>
    <Header background="white" pad="medium" height="xxsmall" elevation="xsmall">
      <Box fill="horizontal" direction="column" align="center">
        <Box width="large">
          <Anchor href="https://rowanstringer.com/">
            <Box
              height="xxsmall"
              direction="row"
              align="center"
              pad="xxsmall"
              gap="small"
            >
              <Image fill="vertical" src={logo} />
              <Heading color="black" level="5">
                TECHTREE
              </Heading>
              <Text color="dark-1" size="xsmall" alignSelf="center">
                TWITTER TO POWERPOINT
              </Text>
            </Box>
          </Anchor>
        </Box>
      </Box>
    </Header>
    <Router basename={"/twitter-to-pptx"}>
      <Switch>
        <Route exact path="/login" component={LoginPage}>
        </Route>
        <Route path="/login/authorized" component={ConverterPage}>
        </Route>
        <Redirect from="*" to="/login" />
      </Switch>
    </Router>
  </Grommet>
);

export default App;
