import React from "react";
import axios from "axios";
import queryString from "query-string";
import TweetList from "../components/TweetList";
import config from "../config";
import { Heading, Box, Text, Spinner } from "grommet";

export default class ConverterPage extends React.Component {
  searchResult = "";
  version = require("../../package.json").version;
  searchInput = null;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;
    const values = queryString.parse(search);
    this.getAccessToken(values.oauth_token, values.oauth_verifier);
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      // navigated!
    }
  }

  getAccessToken = (token, verifier) => {
    axios
      .get(
        `${config.BACKEND}/auth/twitter/access-token?oauth_token=${token}&oauth_verifier=${verifier}`
      )
      .then((response) => {
        console.log("Get access token response:", response);
        const values = queryString.parse(response.data);
        this.setState({
          user: {
            screenName: values.screen_name,
            accessToken: values.oauth_token,
            accessTokenSecret: values.oauth_token_secret,
          },
        });
      });
  };

  setTweetValue = (value) => {
    this.setState({ tweetUrl: value });
  };

  retrieveSelected = () => {
    this.setState({ activeTweetUrl: this.state.tweetUrl });
  };

  render() {
    const { user, activeTweetUrl } = this.state;

    return (
      <Box fill="horizontal" direction="column" align="center" pad="medium">
        <Box width="large" justify="center">
          <Heading level="2" pad="small" color="dark-1">
            Twitter To Powerpoint Converter
          </Heading>
          <Text pad="small" color="dark-1" margin={{"vertical":"small"}}>
            Provide a link to the first tweet in the thread
          </Text>
          <Text size="small" pad="small" color="dark-1">
          • Select 'Copy link to Tweet' under the share option on the relevant tweet
          </Text>
          <Text size="small" pad="small" color="dark-1">
          • Only Tweets made within the last 7 days can be found due to limitations of the Twitter API
          </Text>
          {user ? (
            <TweetList
              screenName={user.screenName}
              accessToken={user.accessToken}
              accessTokenSecret={user.accessTokenSecret}
              tweetUrl={activeTweetUrl}
            />
          ) : (
            <Box width="large" align="center" pad="large">
              <Spinner></Spinner>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}
