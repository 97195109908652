import axios from "axios";
import React from "react";
import { Button, Box, Text, Image } from "grommet";

import config from "../config";
import loginImage from "../assets/images/login.png";

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twitterAuthUrl: "",
    };
  }

  componentDidMount() {
    this.getAuthToken();
  }

  setAuthUrl = (authUrl) => {
    this.setState({
      twitterAuthUrl: authUrl,
    });
  };

  getAuthToken = () => {
    axios.get(`${config.BACKEND}/auth/twitter/reverse`).then((response) => {
      this.setAuthUrl(
        `https://api.twitter.com/oauth/authorize?${response.data}`
      );
    });
  };

  renderSignIn = () => {
    const { twitterAuthUrl } = this.state;

    if (!twitterAuthUrl) {
      return null;
    }
    return (
      <Box
        width="full"
        justify="center"
        gap="xsmall"
        margin="medium"
        align="center"
      >
        <Box width="medium">
          <Box width="medium">
            <Image src={loginImage} />
          </Box>
          <Text size="small" pad="small" margin="medium" color="dark-1">
            Please authorize TechTree to read public tweets on your behalf to continue.
          </Text>
        </Box>
        <a size="lg" href={twitterAuthUrl}>
          <Button
            primary
            label="Sign in with Twitter"
            border={{ radius: "0" }}
            onClick={this.handleSearch}
          />
        </a>
      </Box>
    );
  };

  render() {
    return this.renderSignIn();
  }
}
